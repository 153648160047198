<template>
  <div class="footer">
    <router-link to="/" class="item">Главная</router-link>
    <router-link to="/help" class="item">Помощь</router-link>

    <DarkmodeSwitch
      class="item"
      :darkmode="darkmode"
      @darkmode-clicked="$emit('darkmodeClicked')"
    />
  </div>
</template>

<script>
import DarkmodeSwitch from "./DarkmodeSwitch.vue";

export default {
  name: "Footer",
  components: { DarkmodeSwitch },
  props: { darkmode: Boolean },
};
</script>


<style scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  font-size: 18px;
}
.item {
  display: flex;
  margin: 0 10px;
}
a {
  color: #777;
  text-decoration: none;
}
</style>