<template>
  <div class="darkmode-switch" @click="$emit('darkmodeClicked')">
    <span v-if="!darkmode">Темная тема</span>
    <span v-if="darkmode">Светлая тема</span>
  </div>
</template>

<script>
export default {
  name: "DarkmodeSwitch",
  props: { darkmode: Boolean },
};
</script>

<style scoped>
.darkmode-switch { 
  display: flex;
  color: #777;
  cursor: pointer;
}
</style>

