<template>
  <div
    class="text"
    :class="{ text__nocursor: hideCursor }"
    @mousemove="onMouseMove"
  >
    <span v-for="(item, index) in highlight" :key="index" :class="item.class">
      <span
        v-html="item.char"
        :class="{ cursor: index == typedText.length }"
      ></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "Text",
  props: ["originalText", "typedText"],
  watch: {
    originalText: function () {
      this.highlight = this.getHighlight();
    },
    typedText: function () {
      this.highlight = this.getHighlight();
      this.hideCursor = true;
    },
  },
  data() {
    return {
      highlight: this.getHighlight(),
      hideCursor: false,
    };
  },
  created() {},
  methods: {
    getHighlight() {
      let result = [];
      let maxLength = Math.max(this.originalText.length, this.typedText.length);
      for (let i = 0; i < maxLength; i++) {
        let originalChar = this.originalText[i];
        let typedChat = this.typedText[i];

        if (typedChat === undefined) {
          result.push({ char: originalChar, class: "" });
        } else if (originalChar === typedChat) {
          result.push({ char: originalChar, class: "right" });
        } else if (originalChar !== typedChat) {
          if (typedChat == " ") {
            result.push({ char: "&nbsp;", class: "wrong-space" });
          } else {
            result.push({ char: typedChat, class: "wrong" });
          }
        }
      }
      return result;
    },
    onMouseMove() {
      this.hideCursor = false;
    },
  },
};
</script>

<style scoped>
.text {
  width: 1000px;
  margin: 50px 0;
  font-size: 40px;
  font-family: "Anonymous Pro";
}
.text__nocursor {
  cursor: none;
}
.cursor {
  border-left: solid 1px rgba(255, 255, 255, 0.767);
  height: 44px;
}
.right {
  color: green;
}
.wrong {
  color: red;
}
.wrong-space {
  border-bottom: solid 1px red;
}

.darkmode .text {
  color: rgba(255, 255, 255, 0.767);
}
.darkmode .right {
  color: #117a65;
}
</style>

