<template>
  <div class="alphabet">
    <AlphabetChar
      :char="char.char"
      :confidence="char.confidence"
      v-for="char in alphabet"
      :key="char.char"
      @click="$emit('alphabetCharClicked', char)"
    />
  </div>
</template>

<script>
import AlphabetChar from "./AlphabetChar.vue";

export default {
  name: "Alphabet",
  components: { AlphabetChar },
  props: ["alphabet", "confidence"],
};
</script>

<style scoped>
.alphabet {
  margin: 50px 0;
}
</style>

