<template>
  <div class="mode-switch">
    <div v-for="(group, index) in groups" :key="index">
      <span
        class="mode-switch__mode"
        :class="{ selected: selectedModes.includes(mode) }"
        @click="$emit('modeClicked', mode)"
        v-for="mode in group"
        :key="mode"
        >{{ mode }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ModeSwitch",
  props: { modes: Array, selectedModes: Array },
  data() {
    return {
      groups: [this.modes.slice(0, 3), this.modes.slice(3)],
    };
  },
};
</script>

<style scoped>
.mode-switch {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.mode-switch__mode {
  padding: 5px;
  color: #777;
  border: solid 1px #777;
  cursor: pointer;
}
.selected {
  color: rgb(18, 148, 235);
  border-color: rgb(18, 148, 235);
}


.darkmode .selected {
  color: #1A5276;
  border-color: #1A5276
}
</style>

