<template>
  <div class="practice-view">
    <Text :originalText="originalText" :typedText="typedText" />

    <Dashboard
      :practiceChar="practiceChar"
      :cpm="cpm"
      :wpm="wpm"
      :accuracy="accuracy"
      @refresh="startNewDrill"
    />

    <Alphabet :alphabet="alphabet" @alphabet-char-clicked="startNewDrill" />

    <ModeSwitch
      :modes="modes"
      :selectedModes="settings.selectedModes"
      @modeClicked="modeClicked"
    />

    <LangSwitch
      @lang-switch-click="changeLanguage"
      :languages="languages"
      :currentLanguageCode="settings.langCode"
    />
  </div>
</template>

<script>
import _ from "lodash";
import Alphabet from "../components/Alphabet.vue";
import Dashboard from "../components/Dashboard.vue";
import Text from "../components/Text.vue";
import LangSwitch from "../components/LangSwitch.vue";
import ModeSwitch from "../components/ModeSwitch.vue";

const LANGUAGES = {
  en: {
    name: "English",
    alphabet: "abcdefghijklmnopqrstuvwxyz",
  },
  ru: {
    name: "Русский",
    alphabet: "абвгдеёжзийклмнопрстуфхцчшщъыьэюя",
  },
  uk: {
    name: "Українська",
    alphabet: "абвгґдеєжзиіїйклмнопрстуфхцчшщьюя'",
  },
  pl: {
    name: "Polski",
    alphabet: "aąbcćdeęfghijklłmnńoópqrsśtuvwxyzźż",
  },
  es: {
    name: "Español",
    alphabet: "aábcdeéfghiíjklmnñoópqrstuúüvwxyz",
  },
  de: {
    name: "Deutsch",
    alphabet: "aäbcdefghijklmnoöpqrsßtuüvwxyz",
  },
};



let MODES = [
  {
    name: "abc",
    apply: function (text) {
      return text.toLowerCase();
    },
  },
  {
    name: "Abc",
    apply: function (text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
  },
  {
    name: "ABC",
    apply: function (text) {
      return text.toUpperCase();
    },
  },
  {
    name: ".!?",
    apply: function (text, langCode) {
      let chars = ',.;?!"';
      if (langCode == "en") {
        chars += "&'";
      } else if (langCode == "es") {
        chars += "¿¡";
      }

      return text + "" + _.sample(chars);
    },
  },
  {
    name: "123",
    apply: function (text) {
      return text + " " + _.sample("01234567890");
    },
  },
  {
    name: "+-=",
    apply: function (text) {
      return text + " " + _.sample("+-=*/^%<>()");
    },
  },
  {
    name: "#[{",
    apply: function (text, langCode) {
      let chars = "{}[]";
      if (langCode == "en") {
        chars += "@#$";
      }
      return text + " " + _.sample(chars);
    },
  },
];

export default {
  name: "Practice",
  components: {
    Text,
    Alphabet,
    Dashboard,
    LangSwitch,
    ModeSwitch,
  },
  props: {settings: Object},
  data() {
    return {
      modes: MODES.map((x) => x.name),
      practiceChar: { char: LANGUAGES["en"].alphabet[0], confidence: 0 },
      originalText: "Loading...",
      typedText: "",
      startTime: 0,
      cpm: 0,
      wpm: 0,
      pressedKeys: [],
      stat: {},
      alphabet: [],
      languages: Object.entries(LANGUAGES).map(([code, lang]) => {
        return { code: code, name: lang.name };
      }),
      errorPositions: [],
      accuracy: 100,
    };
  },
  async created() {
    await this.changeLanguage(this.settings.langCode);
    this.startNewDrill();
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("keyup", this.onKeyUp);
  },
  methods: {
    loadStat() {
      try {
        return JSON.parse(
          localStorage.getItem("stat-" + this.settings.langCode) || "{}"
        );
      } catch (e) {
        return {};
      }
    },
    saveStat() {
      localStorage.setItem(
        "stat-" + this.settings.langCode,
        JSON.stringify(this.stat)
      );
    },
    updateStat(originalChar, typedChar) {
      if (typeof this.stat[originalChar] === "undefined") {
        this.stat[originalChar] = [];
      }
      this.stat[originalChar].push(typedChar);

      if (this.stat[originalChar].length > 40) {
        this.stat[originalChar].shift();
      }
    },
    async changeLanguage(code) {
      this.language = LANGUAGES[code];
      this.language.words = await this.fetchWords(code);

      this.stat = this.loadStat();
      this.alphabet = this.getAlphabet();
      this.startNewDrill();

      this.$emit('updateSettings', {'langCode': code})
    },
    async fetchWords(code) {
      let response = await fetch(`/words/${code}.txt`);
      let text = await response.text();
      return text.split(/\r?\n/g);
    },
    startNewDrill(char) {
      if (typeof char === "undefined") {
        this.practiceChar = this.getLessCondifentChar();
      } else {
        this.practiceChar = char;
      }

      this.refresh();

      this.originalText = this.applyModes(
        this.getWordsWith(this.practiceChar.char)
      );
    },
    refresh() {
      this.typedText = "";
      this.startTime = 0;
      this.errorPositions = [];
    },
    applyModes(text) {
      let result = [];
      let modeMap = Object.fromEntries(MODES.map((x) => [x.name, x]));

      for (let word of text.split(" ")) {
        let modifedWord = word;
        for (let modeName of this.settings.selectedModes) {
          modifedWord = modeMap[modeName].apply(
            modifedWord,
            this.settings.langCode
          );
        }
        result.push(modifedWord);
      }
      return result.join(" ");
    },
    modeClicked(mode) {
      let selectedModes = [...this.settings.selectedModes]

      if (selectedModes.includes(mode)) {
        if (mode.toLowerCase() !== "abc") {
          selectedModes = selectedModes.filter(
            (x) => x !== mode
          );
        }
      } else {
        if (mode.toLowerCase() === "abc") {
          selectedModes = selectedModes.filter(
            (x) => x.toLowerCase() !== "abc"
          );
        }
        selectedModes.push(mode);
      }

      this.$emit('updateSettings', {'selectedModes': selectedModes})
      this.startNewDrill();
    },
    getLessCondifentChar() {
      return [...this.alphabet]
        .sort(function (char1, char2) {
          if (char1.confidence > char2.confidence) return 1;
          if (char1.confidence < char2.confidence) return -1;
          return 0;
        })
        .shift();
    },
    getRandomText(words) {
      let result = _.shuffle(words);

      if (result.length < 20) {
        for (let i = 0; i < 20; ++i) {
          result.push(...result);
          if (result.length >= 20) {
            break;
          }
        }
      }

      return result.slice(0, 20).join(" ");
    },
    getWordsWith(char) {
      let words = this.language.words.filter((w) => w.search(char) !== -1);
      return this.getRandomText(words);
    },
    getCpm() {
      if (this.typedText.length < 2) {
        return 0;
      }
      let seconds = (Date.now() - this.startTime) / 1000;
      // Вычитаем 1, потому что отчет времени начинаем после нажатия первой клавиши
      return Math.round((60 * (this.typedText.length - 1)) / seconds);
    },
    getWpm() {
      return Math.round(this.getCpm() / 5);
    },
    getAccuracy() {
      if (this.typedText.length == 0) {
        return 100;
      }

      let accuracy =
        100 * (1 - this.errorPositions.length / this.typedText.length);
      return Math.round(accuracy * 10) / 10;
    },
    getAlphabet() {
      let result = [];
      for (let char of this.language.alphabet) {
        result.push({
          char: char,
          confidence: this.getConfidence(char),
        });
      }
      return result;
    },
    getConfidence(char) {
      let typedChars = (this.stat || {})[char] || [];
      if (typedChars.length < 10) {
        return 0;
      }

      let rightCnt = typedChars.filter((x) => x == char).length;
      return Math.round((100 * rightCnt) / typedChars.length) / 100;
    },
    onKeyDown(e) {
      if (e.key !== "Meta") {
        // "Meta" получается когда с зажатым Shift нажимаешь Alt
        this.pressedKeys.push(e.key);
      }

      if (
        this.pressedKeys.sort().toString() ==
        ["Control", "Backspace"].sort().toString()
      ) {
        if (this.typedText.endsWith(" ")) {
          this.typedText = this.typedText.trimEnd();
        } else {
          var lastSpaceIndex = this.typedText.lastIndexOf(" ");
          this.typedText = this.typedText.substring(0, lastSpaceIndex + 1);
        }
      } else if (e.key == "Backspace") {
        this.typedText = this.typedText.slice(0, this.typedText.length - 1);
        if (this.typedText.length == 0) {
          this.refresh();
        }
      } else if (this.pressedKeys.includes("Control")) {
        // ignore
      } else if (
        this.typedText.length >= this.originalText.length &&
        (e.key == "Enter" || e.key == " ")
      ) {
        this.startNewDrill();
      } else if (e.key.length === 1) {
        let typedChar = e.key;
        this.typedText += typedChar;
        let originalChar = this.originalText[this.typedText.length - 1];

        if (this.startTime == 0) {
          this.startTime = Date.now();
        }

        if (this.typedText.length >= 2) {
          // Обновляем статистику по символу, если в передудыдущем символе нет ошибки.
          // Первый симол пропускаем, т.к. ошибка в первом символе скорее всего
          // связана с тем, что не переключили язык.
          let prevOriginalChar = this.originalText[this.typedText.length - 2];
          let prevTypedChar = this.typedText[this.typedText.length - 2];

          if (prevOriginalChar === prevTypedChar) {
            this.updateStat(originalChar, typedChar);
          }
        }

        if (this.typedText.length == this.originalText.length) {
          window.ym(88017405,'reachGoal','exercise_completed')
          this.saveStat();
        }

        if (typedChar != originalChar) {
          this.errorPositions.push(this.typedText.length - 1);
        }
      }

      this.cpm = this.getCpm();
      this.wpm = this.getWpm();
      this.accuracy = this.getAccuracy();

      this.alphabet = this.getAlphabet();
    },
    onKeyUp(e) {
      this.pressedKeys = this.pressedKeys.filter(
        (el) => el.toLowerCase() !== e.key.toLowerCase()
      );
    },
  },
};
</script>

<style>

</style>
