<template>
  <div class="dashboard">
    <span>
      <AlphabetChar
        :char="practiceChar.char"
        :confidence="practiceChar.confidence"
      />
      CPM {{ cpm }} / WPM {{ wpm }},
      точность: {{ accuracy }} %
    </span>

    <span class="refresh-button" @click="$emit('refresh')">⟳</span>
  </div>
</template>

<script>
import AlphabetChar from "./AlphabetChar.vue";

export default {
  name: "Dashboard",
  props: { practiceChar: Object, cpm: Number, wpm: Number, accuracy: Number },
  components: { AlphabetChar },
};
</script>

<style scoped>
.dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1000px;
  font-size: 28px;
  color: #555;
}
.refresh-button {
  color: #555;
  font-size: 30px;
  cursor: pointer;
}
.refresh-button:hover {
  color:rgb(26, 119, 206);
}
</style>

