<template>
  <div class="help-view">
    <h1>Помощь</h1>

    <p>
      <strong>KeyPresso</strong> - это тренажер слепой печати, для тех кто уже
      освоил базовые навыки и хочет увеличить скорость и уменьшить количество
      ошибок.
    </p>

    <p>
      При выполнении упражнений, собирается статистика по каждой клавише, а
      буквы алфавита меняют цвет.
    </p>

    <p>
      <AlphabetChar char="А" confidence="0" /> - статистика еще не собрана,
      продолжайте печатать
    </p>
    <p><AlphabetChar char="Б" confidence="1" /> - отличный уровень точности</p>
    <p>
      <AlphabetChar char="В" confidence="0.9" />- есть ошибки, нужно поработать
    </p>
    <p><AlphabetChar char="Г" confidence="0.8" /> - много ошибок</p>
    <p><AlphabetChar char="Д" confidence="0.7" /> - очень много ошибок</p>

    <p>
      Для каждого упражнения выбирается одна буква, так чтобы все выбранные
      слова содержали её. Сначала выбираются "серые" буквы, т.е. те по которым
      нет статистики. Далее выбираются буквы с наибольшим количеством ошибок.
    </p>

    <p><strong>CPM</strong> - количество символов в минуту.<br /></p>

    <p>
      <strong>WPM</strong> - количество слов в минуту. Это общепринятая метрика
      для англйиского языка, в котором длинна среднего слова - 5 букв.
    </p>
    <p>
      <strong>Точность</strong> зависит от количества ошибок. От 97% и выше
      считается хорошо. Если точность 100%, возможно вы можете печатать быстрее.
    </p>

    <p><strong>Backspace</strong> - стирает предыдущую букву.</p>

    <p>
      <strong>Ctrl + Backspace</strong> - стирает предыдущее слово до пробела.
    </p>

    <p>
      Для перехода к следующему упраженению нужно нажать клавишу
      <strong>Пробел</strong> или <strong>Enter</strong> (при условии, что текст
      допечатан до конца) или кликнуть на кнопку <strong>⟳</strong>.
    </p>
  </div>
</template>

<script>
import AlphabetChar from "../components/AlphabetChar.vue";
export default {
  name: "Help",
  components: { AlphabetChar },
};
</script>


<style scoped>
.help-view {
  width: 1000px;
}

.darkmode .help-view {
  color: #ccc;
}
</style>