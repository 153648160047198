<template>
  <div class="main">
    <router-view :settings="settings" @updateSettings="updateSettings" />
  </div>
  <Footer :darkmode="settings.darkmode" @darkmode-clicked="toogleDarkmode" />
</template>

<script>
let DEFAULT_SETTINGS = {
  langCode: navigator.language.startsWith("ru") ? "ru" : "en",
  selectedModes: ["abc"],
  darkmode: true,
};

import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: { Footer },
  data() {
    return {
      settings: this.getSettings(),
    };
  },
  created() {
    this.setTheme();
  },
  methods: {
    getSettings() {
      let settings;
      try {
        settings =
          JSON.parse(localStorage.getItem("settings")) || DEFAULT_SETTINGS;
      } catch (e) {
        settings = DEFAULT_SETTINGS;
      }
      return settings;
    },
    saveSettings() {
      localStorage.setItem("settings", JSON.stringify(this.settings));
    },
    updateSettings(newSettings) {
      for (let [k,v] of Object.entries(newSettings)) {
        this.settings[k] = v
      }
      this.saveSettings();
    },
    toogleDarkmode() {
      this.settings.darkmode = !this.settings.darkmode;
      this.updateSettings({ darkmode: this.settings.darkmode });
      this.setTheme();
    },
    setTheme() {
      if (this.settings.darkmode) {
        document.body.classList.add("darkmode");
      } else {
        document.body.classList.remove("darkmode");
      }
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;
  background-color: #fef5e7;
}
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

a {
  color: #2980b9;
}

.darkmode {
  background-color: black;
  color: #ccc;
}
.darkmode .a {
  color: #1a5276;
}
</style>
