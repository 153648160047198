<template>
  <div class="lang-switch">
    <span
      class="lang-switch__lang"
      :class="{ current: currentLanguageCode == lang.code }"
      @click="$emit('langSwitchClick', lang.code)"
      v-for="lang in languages"
      :key="lang"
      >{{ lang.name }}</span
    >
  </div>
</template>

<script>
export default {
  name: "LangSwitch",
  props: { languages: Array, currentLanguageCode: String },
};
</script>

<style scoped>
.lang-switch {
  margin: 40px 0 30px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.lang-switch__lang {
  color: #2980b9;
  cursor: pointer;
  font-size: 22px;
}
.current {
  text-decoration: underline;
}

.darkmode .lang-switch__lang {
  color: #1a5276;
}
</style>

