<template>
  <span
    class="alphabet__char"
    :class="{
      alphabet__char__5: confidence >= 0.97,
      alphabet__char__4: confidence >= 0.9 && confidence < 0.97,
      alphabet__char__3: confidence >= 0.8 && confidence < 0.9,
      alphabet__char__2: confidence > 0 && confidence < 0.8,
    }"
    :title="confidence"
    >{{ char }}</span
  >
</template>

<script>
export default {
  name: "AlphabetChar",
  props: { char: String, confidence: Number },
};
</script>

<style scoped>
.alphabet__char {
  color: black;
  background-color: #979a9a;
  font-size: 24px;
  padding: 5px;
  margin: 2px;
  text-transform: uppercase;
  cursor: pointer;
}
.alphabet__char__5 {
  background-color: #58d68d;
}
.alphabet__char__4 {
  background-color: #f7dc6f;
}
.alphabet__char__3 {
  background-color: #f5b041;
}
.alphabet__char__2 {
  background-color: #ec7063;
}

.darkmode .alphabet__char__5 {
  background-color: #117a65;
}
.darkmode .alphabet__char__4 {
  background-color: #9a7d0a;
}
.darkmode .alphabet__char__3 {
  background-color: #ca6f1e;
}
.darkmode .alphabet__char__2 {
  background-color: #922b21;
}
</style>

