import { createRouter, createWebHistory } from 'vue-router'
import Practice from './views/Practice.vue'
import Help from './views/Help.vue'


const routes = [
    {
        path: '/',
        name: 'Practice',
        component: Practice,
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
